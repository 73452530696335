<template>
  <div>
    <CoolLightBox
      v-if="menuImages.length"
      :items="menuImages"
      :index="currentImageIndex"
      :fullScreen="true"
      :useZoomBar="true"
      effect="fade"
      @close="currentImageIndex = null"
    >
    </CoolLightBox>
    <v-expansion-panels multiple v-model="expansionPanels">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <template v-slot:actions>
            <v-btn fab max-width="24" max-height="24" depressed x-small color="#B3B3B3"
              ><v-icon small color="white">$expand</v-icon></v-btn
            >
          </template>
          <div class="d-flex justify-space-between">
            <div class="f-17 text--primary">{{ form.location_types.name }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat color="white">
            <v-card-text class="pb-0 mb-0">
              <v-row>
                <v-col cols="12" class="py-1 my-1">
                  <div class="body-2 font-weight-bold text-uppercase">
                    {{ form.location_types.name === "Restaurant" ? "Cuisines" : "Bar Type" }}
                  </div>
                  <div class="black--text body-1" v-if="form.location_types.name === 'Restaurant'">
                    <div class="flex-wrap d-flex">
                      <v-chip v-for="cusine in form.cuisines" :key="`cusine-${cusine}`" class="my-1 mr-2">
                        {{ cusine }}
                      </v-chip>
                    </div>
                  </div>

                  <div class="black--text body-1" v-else>
                    {{ form.bar_type }}
                  </div>
                </v-col>
                <v-col cols="5" class="py-1 my-1" v-if="form.price_range">
                  <div class="body-2 font-weight-bold text-uppercase">
                    Price Range
                  </div>
                  <div class="black--text body-1">
                    {{ form.price_range }}
                  </div>
                </v-col>

                <v-col cols="5" class="py-1 my-1" v-if="form.seating_capacity">
                  <div class="body-2 font-weight-bold text-uppercase">
                    Seating Capacity
                  </div>
                  <div class="black--text body-1">
                    {{ form.seating_capacity | commaSeparator }}
                  </div>
                </v-col>

                <v-col cols="12" class="py-1 my-1">
                  <div class="mb-2 body-2 font-weight-bold text-uppercase">
                    Menu
                  </div>
                  <div class="flex-wrap d-flex ml-n2">
                    <v-card
                      v-for="(image, index) in menuImages"
                      :key="index"
                      :style="{
                        'background-image': `url('${image.url}') !important`,
                        'background-size': 'cover'
                      }"
                      color="white"
                      class="text-right ma-2 align-center custom-bg-style"
                      height="75"
                      width="75"
                      @click.self="currentImageIndex = index"
                    >
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  components: { CoolLightBox },
  props: ["form"],
  data: () => ({
    expansionPanels: [0],
    currentImageIndex: null
  }),
  computed: {
    menuImages() {
      let vendor = this.getNewVendor;
      if (!vendor || !vendor.name) {
        vendor = this.getVendor;
      }
      if (!vendor || !vendor.name) {
        vendor = this.form;
      }
      const venueType = this.form.location_types.name.toLowerCase();
      let images =
        vendor.gallery && vendor.gallery.length
          ? vendor.gallery.filter(image => image.property == venueType + "-menu")
          : [];

      // cool lightbox shows images on the base of src property
      images.forEach(image => {
        image.src = image.url;
      });
      return images;
    },
    ...mapGetters(["getNewVendor", "getVendor"])
  }
};
</script>
<style scoped>
.v-expansion-panel {
  background-color: #f9f9f9 !important;
}
.theme--light.v-card {
  background-color: #f9f9f9 !important;
}
</style>
